





































































  import { Vue, Component, Prop } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Products } from '@one/types'
  import { AxiosError } from 'axios'
  import SearchResultPager = Products.Responses.SearchResultPager;
  import uuid from 'uuid-random'

  const layout = namespace('layout')
  const products = namespace('products')
  @Component({
    components: {
      OneBarcodeScannerProduct: () =>
        import('~/components/organisms/shared/OneBarcodeScannerProduct.vue'),
      OneStreamBarcodeReader: () =>
        import('~/components/atoms/readers/OneStreamBarcodeReader.vue'),
    },
  })
  export default class OneBarcodeScanner extends Vue {
    @Prop({
      type: String,
      required: false,
    })
    readonly scannerIcon!: string

    @Prop({
      type: Boolean,
      required: false,
    })
    readonly showSearchOnTheRight!: boolean

    name: string = 'one-barcode-scanner'
    showScanner = false
    currentProducts: Array<Products.Product> | null = null
    searchValue: string = ''
    isScannerLoaded = false
    waiterName = 'barcode-scanning'
    isSearchComplete = false
    uuid = uuid()

    @layout.Getter getCurrency: any
    @products.Action fetchProductsForSearch: any

    get footerClass() {
      return `justify-content-between ${this.showSearchOnTheRight ? 'flex-row-reverse' : ''}`
    }

    search(catalogIndex: string = ''): void {
      this.$wait.start(this.waiterName)
      if (catalogIndex === this.searchValue) {
        return
      }
      const code = catalogIndex || this.searchValue
      if (!code) {
        return
      }

      this.fetchProductsForSearch({
        q: code,
      })
        .then((data: SearchResultPager) => {
          if (data?.items) {
            this.currentProducts = data.items
            window?.navigator?.vibrate?.([100, 50, 200, 50, 300])
          } else {
            window?.navigator?.vibrate?.([200, 50, 200])
            this.currentProducts = null
          }
        })
        .catch((error: AxiosError) => this.$logger.warn(error))
        .finally(() => {
          this.isSearchComplete = true
          setTimeout(() => {
            this.$wait.end(this.waiterName)
          }, 150)
        })
    }

    decode(decodedText: string): void {
      if (this.isSearchComplete) {
        return
      }
      this.search(decodedText)
      this.searchValue = decodedText
    }

    onScannerInit() {
      this.isScannerLoaded = true
    }

    backToScan() {
      this.isSearchComplete = false
      this.currentProducts = null
      this.searchValue = ''
    }

    reset() {
      this.isSearchComplete = false
      this.currentProducts = null
      this.searchValue = ''
      this.isScannerLoaded = false
    }
  }
